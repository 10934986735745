import { Box } from "@mui/material";
import { Loader } from "./../components";
import { BASENAME } from "./../config";
import React, { Suspense, ReactNode, FC } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "apps/Login/Login";
import { getToken } from "../utils/utils";
import MainLayout from "./Main/MainLayout";
import Footer from "./Main/Footer";
import Header from "./Main/Header";
import WebPage from "apps/Web";
import ReloadHistory from "apps/ReloadHistory";
import ProviderCommissions from "apps/Commissions/ProviderCommissions";
const Home = React.lazy(() => import("../apps/Home"));

const PrivateRoute = ({
  path,
  children,
}: {
  path?: string;
  children?: ReactNode;
}) => {
  const isAuth = getToken() !== "";
  return isAuth ? (
    <MainLayout
      footer={isAuth ? <Footer /> : <></>}
      header={isAuth ? <Header data-testid="app-header" /> : <></>}
    >
      {children}{" "}
    </MainLayout>
  ) : (
    <Navigate to="/" />
  );
};

/**
 * AppRoutes will load the app routes.
 * @returns
 */
// eslint-disable-next-line react/no-multi-comp
const AppRoutes = () => {
  return (
    <Box>
      <Routes>
        <Route
          element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          }
          path="/ad_login"
        />
        <Route
          element={
            <PrivateRoute>
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            </PrivateRoute>
          }
          path="/dashboard"
        />
        <Route
          element={
            <PrivateRoute>
              <Suspense fallback={<Loader />}>
                <ProviderCommissions />
              </Suspense>
            </PrivateRoute>
          }
          path="/commissions"
        />
        <Route
          element={
            <PrivateRoute>
              <Suspense fallback={<Loader />}>
                <ReloadHistory />
              </Suspense>
            </PrivateRoute>
          }
          path="/history"
        />

        <Route element={<Navigate replace to={"/dashboard"} />} path="*" />
        <Route
          element={
            <Suspense fallback={<Loader />}>
              <WebPage />
            </Suspense>
          }
          path="/"
        />
      </Routes>
    </Box>
  );
};

export default AppRoutes;
