import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../config';
import { prepareHeaders } from 'utils/utils';
import { IApiResponse } from './apiType';

export const homeAPI = createApi({
    reducerPath: 'homeAPI',
    baseQuery: fetchBaseQuery({ baseUrl: API_URL,prepareHeaders }),
    endpoints: (builder) => ({
        getHomeTop:
            builder.query<IApiResponse, void>({
                query: () => ({
                    url: 'service/admin_dashboard',
                })
        })
    }),
})

export const {
    useGetHomeTopQuery } = homeAPI;
