import { Grid, Typography } from "@mui/material";

const ReloadHistory = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <Typography variant="h1" sx={{fontWeight: 800}} gutterBottom>
          Reload History
        </Typography>
      </Grid>
    </Grid>
  );;
};

export default ReloadHistory;
