
declare global {
  interface Window {
    REACT_APP_TARGET_ENV?: string;
    REACT_APP_API_URL?: string;
  }
}

export const TARGET_ENV =
  window.REACT_APP_TARGET_ENV || process.env.REACT_APP_TARGET_ENV || 'production';
// export const API_URL = window.REACT_APP_API_URL
// || process.env.REACT_APP_API_URL;
export const API_URL = 'http://139.59.247.140:3000/api/v1/'


export const BASENAME = '/';
if (process.env.NODE_ENV === 'production') {
  console.info(`Target env: ${TARGET_ENV}`);
  console.info(`API URL: ${API_URL}`);
}


// BJB links and socil media links
// Later if needed we can get it based on the environment
export const ABOUT_URL = '';
export const CONTACT_URL = '';
export const LEGAL_URL = '';
export const PRIVACY_URL = '';
export const TERMS_URL = 'h';
export const ALERT_URL = 'hl';
export const FACEBOOK_URL = '';
export const TWITTER_URL = '';
export const YOUTUBE_URL = '';
export const LINKEDIN_URL = '';
export const INSTAGRAM_URL = '';
