import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "./component/Header";

const WebPage = () => {
  return (
    <Box>
      <WebHeader />
      <Box>
        <img src={`/images/mobile-recha.jpg`} />
      </Box>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          <Typography variant="h1" sx={{ fontWeight: 800 }} gutterBottom>
            Welcome to Touch.LK
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WebPage;
