import React, { useMemo } from "react";
import "./i18n/config";
import "./@fake-db";
import { Box } from "@mui/material";

import withAppProviders from "./withAppProviders";
import AppRoutes from "./layouts/AppRoutes";
import { BASENAME } from "config";
import { BrowserRouter } from "react-router-dom";

/**
 * Recharge  App
 */
const GsApp = () => {
  return (
    <Box>
      <BrowserRouter basename={BASENAME}>
        <AppRoutes />
      </BrowserRouter>
    </Box>
  );
};

export default withAppProviders(GsApp)();
