/* eslint-disable linebreak-style */
import { gsColor } from './gsColors';

export const palette = {
  background: {
    default: '#FFFFFF',
  },
  primary: {
    light: gsColor.royalBlue[40],
    dark: gsColor.royalBlue[100],
    main: gsColor.royalBlue[100],
    contrastText: gsColor.white,
  },
  secondary: {
    light: gsColor.reflexBlue[40],
    dark: gsColor.reflexBlue[100],
    main: gsColor.reflexBlue[100],
    contrastText: gsColor.white,
  },
  error: {
    light: gsColor.carmineRed[40],
    dark: gsColor.carmineRed[100],
    main: gsColor.carmineRed[90],
    contrastText: gsColor.white,
  },
  warning: {
    light: gsColor.amberYellow[40],
    dark: gsColor.amberYellow[100],
    main: gsColor.amberYellow[90],
    contrastText: gsColor.white,
  },
  info: {
    light: gsColor.persianBlue[40],
    dark: gsColor.persianBlue[100],
    main: gsColor.persianBlue[90],
    contrastText: gsColor.white,
  },
  success: {
    light: gsColor.forestGreen[40],
    dark: gsColor.forestGreen[100],
    main: gsColor.forestGreen[90],
    contrastText: gsColor.white,
  },
  text: {
    primary: gsColor.royalBlue[100],
    secondary: gsColor.black[80],
    hint: gsColor.black[50],
    disabled: gsColor.black[50],
  },
  divider: gsColor.black[20],
  action: {
    selected: gsColor.forestGreen[20],
    hover: gsColor.royalBlue[10],
    disabledBackground: gsColor.black[20],
    disabled: gsColor.black[30],
  },
};
