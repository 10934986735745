import { AppBar, Avatar, Box, Grid, Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const WebHeader = () => {
  const navigate = useNavigate();
  return (
    <AppBar color="inherit" elevation={0} position="sticky">
      <Toolbar disableGutters>
        <Grid container justifyContent="space-between">
          <Grid alignItems="center" container item xs={4}>
            <Box sx={{ marginLeft: "10px" }}>
              <img height="60px" src={`/images/logo.png`} width="80px" />
            </Box>
          </Grid>
          <Grid
            alignItems="center"
            container
            item
            justifyContent="center"
            xs={4}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: 800 }}>
              Welcome to Touch LK
            </Typography>
          </Grid>
          <Grid alignItems="center" container item justifyContent="end" xs={4}>
            <Box alignItems="center" display="flex">
              <Box alignItems="center" display="flex">
                <Box
                 
                >
                  <Typography
                    align="right"
                    component="p"
                    data-testid="header-username"
                    variant="body2"
                    onClick={()=>navigate('/ad_login')}
                  >
                    Login
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default WebHeader;
