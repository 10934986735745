import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { fetchUser, User } from '../../api/user';

type UserState = {
  data: User | null;
  loading: boolean;
  error: boolean;
  loggedIn: boolean;
};

// Initital State values
const initialState: UserState = {
  data: null,
  loading: false,
  error: false,
  loggedIn: false
};

// Thunk for fetching the user information
export const getUserInfo = createAsyncThunk(
  'user/fetch',
  async () => {
    return fetchUser();
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUserInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.loggedIn = true;
      });
  },
});

// Exporting the selectors
export const selectUserState =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (state: any) => state.user;

export const selectUser = createSelector(
  selectUserState,
  (user: UserState) => user?.data
);

export const selectUserLoading = createSelector(
  selectUserState,
  (user: UserState) => user?.loading
);

export const selectLoggedIn = createSelector(
  selectUserState,
  (user: UserState) => user?.loggedIn
);

export default userSlice.reducer;
