import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

/**
 *
 * LinkedIn Icon
 */
function LinkedIn({ ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      fill="none"
      height="14"
      viewBox="0 0 13 14"
      width="13"
      {...rest}
    >
      <path
        d="M3.11525 2.24961C3.11331 2.55746 3.02018 2.85784 2.84763 3.1128C2.67508 3.36776
        2.43085 3.56587 2.14577 3.68211C1.8607 3.79835 1.54758 3.82751 1.24594 3.76591C0.944306 3.7043
        0.667693 3.55469 0.451033 3.33597C0.234372 3.11726 0.0873835 2.83924 0.0286298 2.53704C-0.030124 2.23484
        0.00199251 1.922 0.120923 1.63804C0.239853 1.35407 0.440263 1.11172 0.696843 0.941591C0.953423 0.771457
        1.25466 0.681172 1.56252 0.682137C1.76749 0.682779 1.97032 0.723869 2.15938 0.803051C2.34843 0.882233
        2.52 0.99795 2.66425 1.14357C2.8085 1.28919 2.92259 1.46184 2.99998 1.65164C3.07737 1.84144 3.11655
        2.04464 3.11525 2.24961ZM2.88922 4.93248H0.28005V13.443H2.90888L2.88922 4.93248ZM7.09043
        4.93248H4.58936V13.443H7.11009V8.98628C7.11009 7.79225 7.66042 7.07977 8.71686 7.07977C9.6996
        7.07977 10.1468 7.76277 10.1468 8.98628V13.443H12.7608V8.06742C12.7608 5.79238 11.4685 4.70645 9.67012
        4.70645C9.15811 4.6982 8.65277 4.82344 8.20387 5.06986C7.75498 5.31628 7.37801 5.67537 7.11009 6.11177L7.09043 4.93248Z"
        fill="white"
      />

    </SvgIcon>
  );
}

export default LinkedIn;
