import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box, Theme } from "@mui/system";
import FieldHeader from "components/FieldHeader";
import React, { useMemo } from "react";
import { useFormik } from "formik";
import { gsColor } from "../../theme/gsColors";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "store/rechargeApi";
import { useNotifier } from "core";
import { IApiResponse, IResponseMutation } from "store/apiType";
import { IUser } from "./interface";

const useStyle = makeStyles((theme: Theme) => ({
  searchBox: {
    height: `${theme.spacing(5)}px`,
    width: "100%",
    borderRadius: 0,
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: gsColor.black[60],
        border: `1px solid ${gsColor.black[60]}`,
      },
    },
  },
}));

const Login = () => {
  const [login, { isLoading }] = useLoginMutation();
  const classes = useStyle();
  const navigate = useNavigate();
  const { showErrorMessage } = useNotifier();
  //Form validation
  const formik = useFormik({
    initialValues: {
      mobile: "",
      password: "",
    },
    onSubmit: async (values) => {
      try {
        const user = await login(values).unwrap();
        if (user.status) {
          const userData = user.data as IUser
          if(userData.roleId !== 1){
            showErrorMessage(`you don't have access this application at this moment, please contact admin for more info`);
          }else{
            sessionStorage.setItem("user", JSON.stringify(user.data));
            navigate("/dashboard");
          }
        } else {
          showErrorMessage(user.message);
        }
      } catch (e) {
        
        const error = e as IResponseMutation;
        showErrorMessage(error.data.message);
      }
    },
  });
  const formValid = useMemo(() => {
    return formik.values.mobile === "" || formik.values.password === ""
      ? false
      : true;
  }, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="center"
        spacing={0}
        style={{ minHeight: "100vh" }}
      >
        <Grid item />
        <Card sx={{ minWidth: 400, border: "1px solid #bbbbbb" }}>
          <CardContent sx={{ minWidth: "40wh", padding: "20x" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img src={`/images/logo.png`} width="100px" />
            </Box>
            <Box>
              <FieldHeader title={"User Name"} />
              <TextField
                className={classes.searchBox}
                placeholder="Enter user name"
                size="small"
                {...formik.getFieldProps("mobile")}
              />
            </Box>
            <Box sx={{ paddingTop: "12px" }}>
              <FieldHeader title={"Password"} />
              <TextField
                className={classes.searchBox}
                placeholder="Enter password"
                size="small"
                {...formik.getFieldProps("password")}
                type="password"
              />
            </Box>
          </CardContent>
          <CardActions sx={{ minWidth: "40wh", padding: "16px" }}>
            <Button
              autoFocus
              fullWidth
              sx={{ marginBottom: "8px" }}
              variant="contained"
              type="submit"
              disabled={!formValid || isLoading}
            >
              Login
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </form>
  );
};

export default React.memo(Login);
