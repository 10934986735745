import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../config';
import { IApiResponse, ILogin } from './apiType';
import { prepareHeaders } from 'utils/utils';
import { IPercentage } from 'apps/Commissions/interface';

console.info(API_URL)
export const rechargeAPI = createApi({
  reducerPath: 'rechargeAPI',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL , prepareHeaders}),
  tagTypes: ['Commission'],
  endpoints: (builder) => ({
    login: builder.mutation<IApiResponse, ILogin>({
      query: (request) => ({
        url: `/users/login`,
        method: 'POST',
        body: request,
      }),
    }),
    getDistributor: builder.query<IApiResponse, void>({
      query: () => ({
        url: `/users/list`,
        method: 'GET'
      }),
    }),
    reloadHistoryByNumber: builder.mutation<IApiResponse, string>({
      query: (account) => ({
        url: `/topup/reload/history/${account}`,
        method: 'GET',
      }),
    }),
    getCommissions: builder.query<IApiResponse, void>({
      query: () => ({
        url: `/service/provider_commissions`,
        method: 'GET',
      }),
      providesTags: ['Commission']
    }),
    updateCommissions: builder.mutation<IApiResponse, object>({
      query: (request) => ({
        url: `/service/update_commission`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['Commission']
    }),
  }),
});

export const {
  useLoginMutation,
  useGetDistributorQuery,
  useReloadHistoryByNumberMutation,
  useGetCommissionsQuery,
  useUpdateCommissionsMutation
} = rechargeAPI;
