import { API_URL } from "../config";

export type User = {
    name: string;
    lastLogin: string;
}

/**
 * Fetch the userInformation
 * @returns
 */
 export async function fetchUser():
 Promise<User> {
    const response = await fetch(`${API_URL}/username`);
    return response.json() as Promise<User>;
}
