import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { IconButton, SvgIconProps, Typography } from "@mui/material";
import Hamburger from "components/Icons/Hamburger";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import LogoutIcon from "@mui/icons-material/Logout";
import UpdateIcon from "@mui/icons-material/Update";
import { useNavigate } from "react-router-dom";
import { logout } from "utils/logout";
import AcUnitIcon from '@mui/icons-material/AcUnit';

const navMenus: { title: string; icon: SvgIconProps; path: string }[] = [
  { title: "Home", icon: <HomeWorkIcon />, path: "/dashboard" },
  { title: "Commissions", icon: <AcUnitIcon />, path: "/commissions" },
  { title: "Logout", icon: <LogoutIcon />, path: "logout" },
];

const AppDrawer = () => {
  const [state, setState] = React.useState(false);
  const navigate = useNavigate();
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState(open);
    };

  const logoutApp = (path: string) => {
    if (path !== "") {
      sessionStorage.removeItem("token");
      navigate("/login");
    }
  };
  const list = () => (
    <Box
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      role="presentation"
      sx={{ width: 250 }}
    >
      <Box sx={{ marginLeft: "10px" }}>
        <img height="80px" src={`/images/logo.png`} width="120px" />
      </Box>
      <Divider />
      <List>
        {navMenus.map((nav, index) => (
          <ListItem disablePadding key={nav.title}>
            <ListItemButton onClick={() => {
              if(nav.path === 'logout'){
                logout()
              }
              if(nav.path!== ""){
                navigate(nav.path)
              }
            }}>
              <ListItemIcon>{nav.icon as React.ReactNode}</ListItemIcon>
            
              <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                {nav.title}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={1}>
        <IconButton
          data-testid="header-hamburger"
          edge="start"
          onClick={toggleDrawer(true)}
          size="large"
        >
          <Hamburger fontSize="large" />
        </IconButton>
        <SwipeableDrawer
          anchor={"left"}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          open={state}
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
};

export default React.memo(AppDrawer);
