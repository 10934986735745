import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ResponsiveContainer from "layouts/ResponsiveContainer";
import { useGetCommissionsQuery } from "store/rechargeApi";
import { Loader } from "components";
import { IPercentage, ProviderData } from "./interface";
import EditIcon from "@mui/icons-material/Edit";
import EditProviderCommission  from "./EditProviderCommission";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#252c54",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ProviderCommissions() {
  const [open, setOpen] = React.useState(false);
  const [providerName, setProviderName] = React.useState('');
  const [percentage, setPercentage] = React.useState<IPercentage | undefined>();
  const handleClickOpen = (p: IPercentage, providerName: string) => {
    setOpen(!open);
    setPercentage(p);
    setProviderName(providerName)
  };
  const { data, isLoading, error } = useGetCommissionsQuery();

  const providersData = React.useMemo(() => {
    return data?.data as ProviderData;
  }, [data]);
  return (
    <ResponsiveContainer sx={{ pt: 10 , pb: 10}}>
      {isLoading && <Loader />}
      {!isLoading && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow sx={{ height: "60px" }}>
                <StyledTableCell>Service Name</StyledTableCell>
                <StyledTableCell>Provider Name</StyledTableCell>
                <StyledTableCell align="right">Admin %</StyledTableCell>
                <StyledTableCell align="right">Distributer %</StyledTableCell>
                <StyledTableCell align="right">Merchant %</StyledTableCell>
                <StyledTableCell align="right">Edit</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {providersData.providers.map((row) => (
                <StyledTableRow key={row.providerId}>
                  <StyledTableCell component="th" scope="row">
                    {row.serviceName}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.providerName}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.adminPercent}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.distributorPercent}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.merchantPercent}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() =>
                      handleClickOpen({
                        adminPercentage: row.adminPercent,
                        distributerPercentage: row.distributorPercent,
                        merchantPercentage: row.merchantPercent,
                        providerId: row.providerId
                      },
                     row.providerName)
                    }
                  >
                    <EditIcon />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {open && (
        <EditProviderCommission
          handleCloseDialog={() => setOpen(false)}
          openModel={open}
          percentage = {percentage}
          title={`Edit ${providerName} Commission`}
        />
      )}
    </ResponsiveContainer>
  );
}
