import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

/**
 *
 * Instagram Icon
 */
function Instagram({ ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      fill="none"
      height="15"
      viewBox="0 0 15 15"
      width="15"
      {...rest}
    >
      <path
        d="M13.0917 6.26084H11.8042C11.9831 6.93533 12.0048 7.64191 11.8674 8.32607C11.73 9.01023 11.4372
        9.65369 11.0117 10.2068C10.5862 10.7599 10.0394 11.2078 9.41333 11.5161C8.78728 11.8244 8.09878
        11.9847 7.40096 11.9847C6.70314 11.9847 6.01464 11.8244 5.38859 11.5161C4.76254 11.2078 4.21568 10.7599
        3.79019 10.2068C3.3647 9.65369 3.07197 9.01023 2.93457 8.32607C2.79717 7.64191 2.81878 6.93533 2.99773
        6.26084H1.71018V12.5217C1.70953 12.5963 1.72367 12.6703 1.75178 12.7395C1.77989 12.8086 1.82142 12.8715
        1.87396 12.9245C1.92651 12.9775 1.98903 13.0195 2.05791 13.0483C2.1268 13.077 2.2007 13.0917 2.27533
        13.0917H12.5217C12.5967 13.0924 12.6711 13.0781 12.7406 13.0497C12.8101 13.0213 12.8732 12.9793 12.9262
        12.9262C12.9793 12.8732 13.0213 12.8101 13.0497 12.7406C13.0781 12.6711 13.0924 12.5967 13.0917 12.5217V6.26084ZM13.0917
        2.27533C13.0917 2.2007 13.077 2.1268 13.0483 2.05791C13.0195 1.98903 12.9775 1.92651 12.9245 1.87396C12.8715
        1.82142 12.8086 1.77989 12.7395 1.75178C12.6703 1.72367 12.5963 1.70953 12.5217 1.71018H10.8164C10.7418 1.70953
        10.6678 1.72367 10.5986 1.75178C10.5295 1.77989 10.4666 1.82142 10.4136 1.87396C10.3606 1.92651 10.3185 1.98903
        10.2898 2.05791C10.2611 2.1268 10.2463 2.2007 10.2463 2.27533V3.98551C10.2463 4.1367 10.3064 4.2817 10.4133
        4.3886C10.5202 4.49551 10.6652 4.55557 10.8164 4.55557H12.5217C12.5967 4.55623 12.6711 4.54193 12.7406
        4.51351C12.8101 4.4851 12.8732 4.44313 12.9262 4.39006C12.9793 4.337 13.0213 4.2739 13.0497 4.20444C13.0781
        4.13498 13.0924 4.06055 13.0917 3.98551V2.27533ZM7.40096 4.55557C6.83819 4.55557 6.28807 4.72245 5.82015
        5.03511C5.35222 5.34776 4.98752 5.79215 4.77216 6.31208C4.5568 6.832 4.50045 7.40412 4.61024 7.95607C4.72003
        8.50802 4.99103 9.01502 5.38897 9.41295C5.7869 9.81089 6.2939 10.0819 6.84585 10.1917C7.3978 10.3015 7.96992
        10.2451 8.48984 10.0298C9.00977 9.81439 9.45416 9.44969 9.76681 8.98177C10.0795 8.51385 10.2463 7.96372 10.2463
        7.40096C10.2463 6.64632 9.94657 5.92258 9.41295 5.38897C8.87934 4.85535 8.1556 4.55557 7.40096 4.55557ZM13.0917
        14.8019H1.71018C1.25661 14.8019 0.821622 14.6217 0.500901 14.301C0.180179 13.9803 0 13.5453 0 13.0917V1.71018C0
        1.25661 0.180179 0.821622 0.500901 0.500901C0.821622 0.180179 1.25661 0 1.71018 0H13.0917C13.5453 0 13.9803
        0.180179 14.301 0.500901C14.6217 0.821622 14.8019 1.25661 14.8019 1.71018V13.0917C14.8019 13.5453 14.6217
        13.9803 14.301 14.301C13.9803 14.6217 13.5453 14.8019 13.0917 14.8019Z"
        fill="white"
      />

    </SvgIcon>
  );
}

export default Instagram;
