import { TARGET_ENV } from "./../config";
import store from "./../store";
import { ROOT_ACTIONS } from "./../store/rootReducer";

// TODO: EXTEND THIS FUNCTION TO SHARED LOGOUT AS PER LPI AND EDOCS ONCE AFTER THE GSSO INTEGRATION
export const logout = () => {
  sessionStorage.removeItem('user');
  // store.dispatch({ type: ROOT_ACTIONS.logout });
  // Only for production environment, do the logout via refresh
  if(TARGET_ENV === 'production') {
    window.location.href = '?logout';
  }
};
