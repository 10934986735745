import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

/**
 *
 * Twitter Icon
 */
function Twitter({ ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      fill="none"
      height="16"
      viewBox="0 0 19 16"
      width="19"
      {...rest}
    >
      <path
        d="M18.2052 2.0446C17.5219 2.34442 16.7986 2.54305 16.058 2.63424C16.836 2.1645 17.4187 1.43006 17.6991
        0.565577C16.9649 0.997058 16.1622 1.29953 15.3258 1.45987C14.8124 0.91474 14.1472 0.536301 13.4163 0.373559C12.6854
        0.210818 11.9224 0.271271 11.2263 0.54709C10.5301 0.822909 9.93279 1.30139 9.51167 1.92056C9.09055 2.53974 8.86503
        3.27109 8.86431 4.0199C8.86216 4.30621 8.89516 4.5917 8.96258 4.86997C7.47636 4.79722 6.02214 4.4122 4.69464 3.73998C3.36713
        3.06776 2.19613 2.12343 1.25791 0.968499C0.779231 1.79314 0.633202 2.76934 0.849606 3.69795C1.06601 4.62657 1.62854
        5.43765 2.42245 5.96573C1.82791 5.93897 1.24837 5.7705 0.73214 5.47436V5.51858C0.731991 6.38162 1.03038 7.21814
        1.5767 7.88626C2.12301 8.55438 2.88361 9.01295 3.72949 9.1842C3.40906 9.2709 3.07871 9.3155 2.74676 9.31687C2.51087
        9.3166 2.27556 9.29356 2.0441 9.24807C2.28198 9.9906 2.74632 10.64 3.37198 11.1053C3.99764 11.5706 4.75325 11.8284
        5.53282 11.8425C4.20895 12.883 2.57321 13.4473 0.889379 13.4444C0.592121 13.4436 0.295158 13.4256 0 13.3903C1.70859
        14.4895 3.69776 15.0729 5.72937 15.0708C12.6085 15.0708 16.3577 9.37583 16.3577 4.43757V3.9462C17.0901 3.41877 17.7224
        2.76481 18.2249 2.01512"
        fill="white"
      />
    </SvgIcon>
  );
}

export default Twitter;
