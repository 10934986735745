/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, ThunkDispatch, configureStore } from '@reduxjs/toolkit';
import { homeAPI } from './homeAPI';
import createReducer from './rootReducer';
import { rechargeAPI } from './rechargeApi';

if (process.env.NODE_ENV === 'development' && (module as any).hot) {
  (module as any).hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer.createReducer());
  });
}

const middlewares: Array<any> = [];

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger');
  const logger = createLogger({ collapsed:
    (_getState: any, _action: any, logEntry: any) => !logEntry.error });

  middlewares.push(logger);
}
middlewares.push(homeAPI.middleware)
middlewares.push(rechargeAPI.middleware);

const store: any = configureStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development',
});

store.asyncReducers = {};

export const injectReducer = (key: any, reducer: any) => {
  if (store.asyncReducers[key]) {
    return false;
  }
  store.asyncReducers[key] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
  return store;
};


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch =
  ThunkDispatch<RootState, void, Action>;


export default store;
