import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

/**
 *
 * Facebook Icon
 */
function Facebook({ ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      fill="none"
      height="16"
      viewBox="0 0 8 16"
      width="8"
      {...rest}
    >
      <path
        d="M1.65806 5.33338H0V7.97204H1.65806V15.7111H4.84314V7.93764H7.06564L7.30251 5.33338H4.84314V3.85927C4.84314
        3.24998 4.96913 3.00429 5.57894 3.00429H7.30251V0.301758H5.09513C2.72647 0.301758 1.65806 1.31889
        1.65806 3.24998V5.33338Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export default Facebook;
