import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

/**
 *
 * YouTube Icon
 */
function YouTube({ ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      fill="none"
      height="15"
      viewBox="0 0 21 15"
      width="21"
      {...rest}
    >
      <path
        d="M20.5343 3.30692C20.5343 3.24304 20.3181 1.80824 19.6499 1.13998C19.1045 0.570789
        18.3591 0.235996 17.5714 0.206375H17.4387C14.8099 0.0147411 10.933 0 10.3974 0H10.3335C9.80282
        0 5.91118 4.39319e-08 3.28726 0.206375H3.16933C2.37984 0.241412 1.63473 0.58138 1.09084 1.15472C0.422577
        1.80824 0.206375 3.24304 0.206375 3.31183C0.0766037 4.40948 0.00770647 5.51348 0 6.61875V8.15182C0.00769395
        9.26199 0.0765903 10.3709 0.206375 11.4735C0.206375 11.5324 0.422578 12.9476 1.08593 13.6404C1.63109
        14.2028 2.37192 14.5336 3.15459 14.5642H3.28726C5.91118 14.7509 9.793 14.7706 10.3237
        14.7755H10.3876C10.9182 14.7755 14.8099 14.7755 17.4387 14.5593H17.5566C18.3433 14.5294 19.088
        14.1967 19.6351 13.6306C20.3034 12.9525 20.5098 11.5177 20.5196 11.4538C20.6465 10.3559 20.7154
        9.25208 20.726 8.14691V6.61875C20.7151 5.51196 20.6462 4.4065 20.5196 3.30692H20.5343ZM13.8468
        7.65554L8.38768 10.4907L8.25009 10.5251L8.09777 10.4907C8.05578 10.4637 8.0212 10.4267 7.99718
        10.3829C7.97315 10.3391 7.96043 10.2901 7.96019 10.2401V4.55008C7.96043 4.50016 7.97315 4.45109
        7.99718 4.40732C8.0212 4.36356 8.05578 4.32649 8.09777 4.29948H8.38768L13.8468 7.14943C13.8884
        7.1755 13.9227 7.21173 13.9465 7.2547C13.9703 7.29768 13.9828 7.34599 13.9828 7.39511C13.9828
        7.44423 13.9703 7.49255 13.9465 7.53552C13.9227 7.5785 13.8884 7.61472 13.8468 7.6408"
        fill="white"
      />
    </SvgIcon>
  );
}

export default YouTube;
