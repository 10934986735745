import { AppBar, Box, Grid, Toolbar, Typography, BoxProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ResponsiveContainer from '../ResponsiveContainer';
import { selectUser } from './../../store/user/userSlice';
import { useAppSelector } from './../../store/hooks';
import Avatar from '@mui/material/Avatar';
import dayjs from 'dayjs';
import AppDrawer from './AppDrawer';
import { getUser } from 'utils/utils';
import { useNavigate } from 'react-router-dom';


/**
 * Header Component to show the header of the application based on the Main Layout
 */
const Header = (props: BoxProps) => {

  const { t } = useTranslation(['common']);
  const user = useAppSelector(selectUser);
  const userData = getUser()
  const navigate = useNavigate();
  return (
    <ResponsiveContainer {...props}>
      <AppBar
        color='inherit'
        elevation={0}
        position="sticky"
      >
        <Toolbar
          disableGutters
        >
          <Grid
            container
            justifyContent="space-between"
          >
            <Grid
              alignItems="center"
              container
              item
              xs={4}
            >
              <Box>
                <AppDrawer/>
              </Box>
            </Grid>
            <Grid
              alignItems="center"
              container
              item
              justifyContent="center"
              xs={4}
              onClick ={ () => navigate('/dashboard')}
              sx={{cursor: 'pointer'}}
            >
              <Typography  sx={{fontSize:"20px",fontWeight: 800}}>Welcome to Touch LK</Typography>
              {/* <img
                alt={t('common:app')}
                data-testid="header-logo"
                src={`/images/logo.png`}
                width="150px"
              /> */}
            </Grid>
            <Grid
              alignItems="center"
              container
              item
              justifyContent="end"
              xs={4}
            >
              <Box
                alignItems="center"
                display="flex"
              >
                <Box
                  alignItems="center"
                  display="flex"
                >
                  <Box sx={{ mr: 1, display: { xs: 'none', sm: 'none', md: 'block' } }}>
                    <Typography
                      align="right"
                      component="p"
                      data-testid="header-username"
                      variant='body2'
                    >{userData?.firstName || '-'}</Typography>
                    <Typography
                      component="p"
                      data-testid="header-lastlogin"
                      variant='hint'
                    >Last login: {user?.lastLogin ? dayjs(user?.lastLogin).format('DD-MMM-YYYY, HH:mm A') : ''}</Typography>
                  </Box>
                  <Box data-testid="header-userlogo">
                    <Avatar alt="Remy Sharp" src="https://cdn-icons-png.flaticon.com/512/219/219983.png"  sx={{ width: 30, height: 30 }} />
                  </Box>
                </Box>
               
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </ResponsiveContainer>
  );
};

export default React.memo(Header);
