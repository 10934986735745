import mock, { delay } from '../mock';

const topInfo = [
  { id: '1', title: 'Total Distributer', count: 8, image: 'https://img.freepik.com/free-vector/hand-painted-watercolor-pastel-sky-background_23-2148902771.jpg?w=2000' },
  { id: '2', title: 'Total Merchant',count: 43,  image: 'https://img.freepik.com/free-vector/hand-painted-watercolor-pastel-sky-background_23-2148902771.jpg?w=2000' },
  { id: '3', title: ' Total Earning', count: 'Rs 34,000.00', image: 'https://img.freepik.com/free-vector/hand-painted-watercolor-pastel-sky-background_23-2148902771.jpg?w=2000' },
  { id: '4', title: 'Current balance',count: 'Rs 124,985.00',  image: 'https://img.freepik.com/free-vector/hand-painted-watercolor-pastel-sky-background_23-2148902771.jpg?w=2000' },
  { id: '4', title: 'Current balance',count: 'Rs 124,985.00',  image: 'https://img.freepik.com/free-vector/hand-painted-watercolor-pastel-sky-background_23-2148902771.jpg?w=2000' }
]
export const homeTopPattern = 'end:/home/top';
mock.get({
  name: 'homeTopPattern',
  url: homeTopPattern
}, topInfo, { delay });
