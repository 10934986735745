
import { Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    fieldHeader: {
      fontSize: `14px`,
      letterSpacing: '1px',
    },
  })
);

const FieldHeader = ({ title, ...rest }: { title: string }) => {
  const classes = useStyles();
  return (
    <Typography variant="caption" className={classes.fieldHeader} color="textSecondary" {...rest}>
      {title}
    </Typography>
  );
};

export default React.memo(FieldHeader);
