import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import { gsColor } from 'theme/gsColors';

/**
 *
 * Hamburger Icon
 */
function Hamburger({ ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      {...rest}
    >
      <line
        stroke={gsColor.royalBlue[100]}
        strokeLinecap="square"
        strokeLinejoin="round"
        x1="4.5"
        x2="27.5"
        y1="7.5"
        y2="7.5"
      />
      <line
        stroke={gsColor.royalBlue[100]}
        strokeLinecap="square"
        strokeLinejoin="round"
        x1="4.5"
        x2="27.5"
        y1="15.5"
        y2="15.5"
      />
      <line
        stroke={gsColor.royalBlue[100]}
        strokeLinecap="square"
        strokeLinejoin="round"
        x1="4.5"
        x2="27.5"
        y1="23.5"
        y2="23.5"
      />
    </SvgIcon>
  );
}

export default Hamburger;
