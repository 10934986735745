import { Box } from '@mui/material';
import { Loader } from './../../components';
import React, { ReactNode, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from './../../store/hooks';
import { getUserInfo, selectLoggedIn } from './../../store/user/userSlice';

interface MainLayoutProps {
  header: ReactNode;
  footer: ReactNode;
  children: ReactNode;
}
/**
 * Main layout which will load the content with the header, children
 */
const MainLayout = ({ header, children, footer, ...rest }: MainLayoutProps) => {
  const dispatch = useAppDispatch();
  // Fetch the user information
  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);
  const loggedIn = useAppSelector(selectLoggedIn);
  return (
    <Box
      {...rest}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        backgroundColor: 'background.default'
      }}
    >
      {header &&
      <Box
        sx={(theme) => ({
          position: 'sticky',
          top: 0,
          zIndex: theme.zIndex.appBar,
        })}
      >{header}</Box>}
      <Box
        sx={{ flex: 1 }}
      >{loggedIn ? children : <Loader data-testid="login-loader"/>}</Box>
      <Box>{footer}</Box>
    </Box>
  );
};

export default React.memo(MainLayout);
