import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { Box, Theme } from "@mui/system";
import { Grid, TextField, useMediaQuery } from "@mui/material";
import FieldHeader from "components/FieldHeader";
import { gsColor } from "theme/gsColors";
import { theme } from "theme";
import { useFormik } from "formik";
import { IPercentage } from "./interface";
import { useUpdateCommissionsMutation } from "store/rechargeApi";
import { useNotifier } from "core";
import { IResponseMutation } from "store/apiType";

const useStyle = makeStyles((theme: Theme) => ({
  searchBox: {
    height: `${theme.spacing(5)}px`,
    width: "100%",
    borderRadius: 0,
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: gsColor.black[60],
        border: `1px solid ${gsColor.black[60]}`,
      },
    },
  },
  dialogPaper: {
    overflowY: "inherit",
  },
}));

interface IEditProviderCommission {
  openModel?: boolean;
  title: string;
  percentage: IPercentage | undefined;
  handleCloseDialog: (close: boolean) => void;
}

const EditProviderCommission = ({
  title,
  openModel = false,
  handleCloseDialog,
  percentage,
}: IEditProviderCommission) => {
  const classes = useStyle();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [updateCommissions, { isLoading }] = useUpdateCommissionsMutation();
  const { showErrorMessage } = useNotifier();

  const formik = useFormik({
    initialValues: {
      providerId: percentage?.providerId,
      adminPercent: percentage?.adminPercentage,
      distributorPercent: percentage?.distributerPercentage,
      merchantPercent: percentage?.merchantPercentage,
    },
    onSubmit: async (values) => {
      console.info(values);
      // TODO: need to add submit validation
    },
  });
  const updateCommission = async () => {
    try {
      const user = await updateCommissions(formik.values).unwrap();
      handleCloseDialog(false);
    } catch (e) {
      const error = e as IResponseMutation;
      showErrorMessage(error.data.message);
    }
   
  };
  const handleClose = () => {
    handleCloseDialog(false);
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          // onClose={handleClose}
          classes={{ paper: classes.dialogPaper }}
          open={openModel}
          maxWidth="lg"
          fullScreen={isMobile}
          fullWidth={isMobile}
        >
          <DialogTitle sx={{ fontWeight: 600, p: 4 }}>{title}</DialogTitle>
          <DialogContent sx={{ width: isMobile ? "auto" : "40vw", p: 4 }}>
            <Box>
              <Grid container justifyContent="space-between" spacing={2}>
                <GridItem>
                  <FieldHeader title={"Admin %"} />
                  <TextField
                    className={classes.searchBox}
                    placeholder="Admin %"
                    type="number"
                    size="small"
                    {...formik.getFieldProps("adminPercent")}
                  />
                </GridItem>
                <GridItem>
                  <FieldHeader title={"Distributer %"} />
                  <TextField
                    className={classes.searchBox}
                    placeholder="Distributer %"
                    type="number"
                    size="small"
                    {...formik.getFieldProps("distributorPercent")}
                  />
                </GridItem>

                <GridItem>
                  <FieldHeader title={"Merchant %"} />
                  <TextField
                    className={classes.searchBox}
                    placeholder="Merchant %"
                    type="number"
                    size="small"
                    {...formik.getFieldProps("merchantPercent")}
                  />
                </GridItem>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ paddingRight: "24px", p: 4, py: 3, gap: 2 }}>
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{ width: 200, height: 42 }}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              type="submit"
              variant="contained"
              sx={{ width: 200, height: 42 }}
              onClick={updateCommission}
              disabled={isLoading}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default React.memo(EditProviderCommission);

// eslint-disable-next-line react/no-multi-comp
const GridItem = ({ ...rest }) => {
  return <Grid item key={1} lg={6} sm={6} xs={12} {...rest} />;
};
