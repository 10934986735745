import dayjs from 'dayjs';
import mock, { delay } from '../mock';

const userInfo = {
  name: 'Sivatharan',
  lastLogin: dayjs(new Date()).subtract(5, 'days').toISOString(),
}
export const userInfoPattern = 'end:/username';
mock.get({
  name: 'userInfo',
  url: userInfoPattern
}, userInfo, { delay });
