export const getToken = (): string => {
  const tokenString = sessionStorage.getItem("user") || "";
  return tokenString;
};

export const getUser = (): IUser | null => {
    const store = sessionStorage.getItem("user");
  if (store) {
    return JSON.parse(store) as IUser;
  }
  return null;
};

export const prepareHeaders = async (
    headers: Headers
  ) => {
    const userData =  getUser() as IUser;
    if (userData && userData.token) {
     
      headers.set('Content-Type', 'application/json');
      headers.set('token', userData.token);
    }

    return headers;
  };

  
export interface IUser {
  id: number;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  displayName: string;
  roleId: number;
  createdOn: string;
  createdBy: number;
  updatedBy: number;
  updatedOn: string;
  token: string;
}
