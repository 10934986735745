import { Grid, IconButton, Link, LinkProps, styled, Typography, Box } from '@mui/material';
import { ABOUT_URL, CONTACT_URL, LEGAL_URL, PRIVACY_URL,
  TERMS_URL, ALERT_URL, FACEBOOK_URL,
  TWITTER_URL, YOUTUBE_URL, LINKEDIN_URL, INSTAGRAM_URL } from './../../config';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { rem } from './../../theme/typography';
import ResponsiveContainer from '../ResponsiveContainer';
import Facebook from './../../components/Icons/Social/Facebook';
import Twitter from './../../components/Icons/Social/Twitter';
import YouTube from './../../components/Icons/Social/YouTube';
import LinkedIn from './../../components/Icons/Social/LinkedIn';
import Instagram from './../../components/Icons/Social/Instagram';


const StyledFooterLink = styled((props: LinkProps) =>
  <Link
    color="inherit"
    target="_blank"
    underline="hover"
    {...props}
  />)(({ theme }) => ({
    fontFamily: 'inherit',
    fontSize: rem(10),
    margin: `${theme.spacing(1)} ${theme.spacing(5)}`,
    marginLeft: 0,
    textTransform: 'uppercase'
}));

/**
 * Footer Component
 */
const Footer = () => {
  const { t } = useTranslation(['home']);

  const links: Array<{label: string, href: string}> = useMemo(() => [
      {
        label: t('home:footer.aboutUs'),
        href: ABOUT_URL
      },
      {
        label: t('home:footer.contact'),
        href: CONTACT_URL
      },
      {
        label: t('home:footer.legal'),
        href: LEGAL_URL
      },
      {
        label: t('home:footer.privacy'),
        href: PRIVACY_URL
      },
      {
        label: t('home:footer.terms'),
        href: TERMS_URL
      },
      {
        label: t('home:footer.alert'),
        href: ALERT_URL
      }
  ], [t]);

  const socialLinks = useMemo(() => [
    { href: FACEBOOK_URL, icon: Facebook, id: 'facebook' },
    { href: TWITTER_URL, icon: Twitter, id: 'twitter' },
    { href: YOUTUBE_URL, icon: YouTube, id: 'youtube' },
    { href: LINKEDIN_URL, icon: LinkedIn, id: 'linkedin' },
    { href: INSTAGRAM_URL, icon: Instagram, id: 'instagram' },
  ], []);
  return <Box
    data-testid="footer-content"
    sx={{ backgroundColor: 'primary.main', minHeight: '100px', color: 'primary.contrastText', fontFamily: 'VerlagSsmBook', py: 4 }}
         >
      <ResponsiveContainer>
        <Grid
          container
          sx={{ flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Box
            sx={{ mb: 3 }}
          >
            { links.slice(0, 3).map(({ label, href }) =>
            <StyledFooterLink
              href={href}
              key={href}
            >{label}</StyledFooterLink>) }
          </Box>
          <Box
            sx={{ mb: 3 }}
          >
            {socialLinks.map(({ href, icon: Icon, id  }) =>
            <IconButton
              color="inherit"
              data-testid={`footer-social-${id}`}
              edge='end'
              href={href}
              key={id}
              sx={{ px: 3 }}
              target="_blank"
            >
              <Icon
                color="inherit"
                fontSize="small"
              />
            </IconButton>)}
          </Box>
        </Grid>
        <Box
          sx={{ mb: 1, display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          justifyContent: {
            xs: 'center',
            sm: 'start'
          },
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
        >
          { links.slice(3, links.length).map(({ label, href }) =>
          <StyledFooterLink
            data-testid={`footer-jb-${label}`}
            href={href}
            key={label}
          >{label}</StyledFooterLink>) }
          <Typography
            color="primary.light"
            component="span"
            variant="hint"
          >{t('home:footer.copyRight')}</Typography>
        </Box>
        <Box >
          <Typography
            color="primary.light"
            component="p"
            data-testid="footer-copyright-text"
            variant="caption"
          >{t('home:footer.copyRightText')}</Typography>
        </Box>
      </ResponsiveContainer>
    </Box>
};

export default React.memo(Footer);
